<template>
  <page-container class="search-goods-container">
    <van-search
        v-model.trim="searchValue"
        :autofocus="true"
        shape="round"
        :clearable="true"
        placeholder="请输入商品名称"
        @search="handleOnSearch"
    />

    <div class="search-title" v-show="searchHistory.length">
      搜索历史
      <div class="clear-search-btn">
        <van-icon name="delete-o" @click="clearSearchHistoryList"/>
      </div>
    </div>
    <div class="history-tag-container">
      <van-tag
          v-for="text in searchHistory"
          :key="text"
          size="large"
          round
          color="#ccc"
          @click="handleHistoryClick(text)"
      >
        {{ text }}
      </van-tag>
    </div>
  </page-container>
</template>

<script>
import { GET_USER_SEARCH_HISTORY, SAVE_USER_SEARCH_HISTORY } from '@/util/storage';

export default {
  name: 'searchGoods',
  data() {
    return {
      searchValue: '',
      searchHistory: [],
    };
  },
  created() {
    this.searchHistory = GET_USER_SEARCH_HISTORY();
  },
  methods: {
    handleOnSearch() {
      let { searchValue } = this;
      if (searchValue && !this.searchHistory.includes(searchValue)) {
        this.searchHistory.push(searchValue);
      }
      SAVE_USER_SEARCH_HISTORY(this.searchHistory);

      this.toGoodsSearchListPage(searchValue);
    },
    clearSearchHistoryList() {
      this.searchHistory = [];
      SAVE_USER_SEARCH_HISTORY(this.searchHistory);
    },
    handleHistoryClick(searchValue) {
      this.searchValue = searchValue;

      this.toGoodsSearchListPage(searchValue);
    },
    toGoodsSearchListPage(searchValue) {
      this.$store.commit('normalGoodsDetail/setRefreshing', true);
      this.$router.push({
        path: '/normalGoodsList',
        query: {
          searchGoodsName: searchValue,
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.search-goods-container {
  background-color: #fff;

  .van-search {

    .van-search__action:active {
      background-color: #0000;
    }

    /deep/ .van-field__left-icon {
      color: #666;
    }
  }

  .search-title {
    margin: 0;
    padding: 0 10px;
    line-height: 30px;
    font-size: 15px;
    color: #333;

    .clear-search-btn {
      float: right;
      width: 30px;
      height: 100%;
      text-align: center;

      .van-icon.van-icon-delete-o {
        font-size: 18px;
      }

    }
  }

  .history-tag-container {
    padding: 10px 5px;

    .van-tag {
      margin: 5px;
    }

  }
}

</style>
